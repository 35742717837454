import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, Column } from '../../components'

import { useStyle } from './cookies.styles'

export const CookiesPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const subTitlePreset = isMobileOnly ? 'h2Mobile' : 'h2'
  const textPreset = isMobileOnly ? 'h7' : 'h5'
  const paddingContainer = '24px 24px 80px'

  return (
    <Content
      className={classes.container}
      padding={paddingContainer}
      justifyContent="flex-start"
    >
      <Text preset={titlePreset} text="Cookie Policy" tx="cookies.name" />
      <Text
        className={classes.name}
        color="inactive"
        preset={textPreset}
        text="Effective on October 1, 2020"
        tx="cookies.date.text"
      />
      <Column className={classes.privacyCont} alignItems="flex-start">
        <Text preset="body" text="Cookies to personalise" tx="cookies.text" />
        <Text
          className={classes.data}
          preset={subTitlePreset}
          text="What technologies are used?"
          tx="cookies.techolog.text"
        />
        <Text
          className={classes.data}
          preset="body"
          text="Personalise content"
          tx="cookies.second.text"
        />
      </Column>
    </Content>
  )
}
