import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, Column, NavLink } from '../../components'

import { useStyle } from './terms-conditions.styles'

export const TermsOfConditionsPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const textPreset = isMobileOnly ? 'h7' : 'h5'
  const paddingContainer = '24px 24px 80px'

  return (
    <Content
      className={classes.container}
      padding={paddingContainer}
      justifyContent="flex-start"
    >
      <Text text="Terms of Use" preset={titlePreset} tx="terms.title.name" />
      <Text
        className={classes.name}
        color="inactive"
        preset={textPreset}
        text="Last Modified: May 3, 2023"
        tx="terms.create.date"
      />
      <Column className={classes.termsText} alignItems="flex-start">
        <p>
          <strong>Acceptance of the Terms of Use</strong>
        </p>
        <p>
          These terms of use are entered into by and between You and Ovaflo,
          Inc. DBA Ovaflopick (&quot;<strong>Company</strong>,&quot; &quot;
          <strong>we</strong>,&quot; or &quot;<strong>us</strong>&quot;). The
          following terms and conditions, together with any documents they
          expressly incorporate by reference (&quot;
          <strong>Terms of Use</strong>&quot;), govern your access to and use of
          www.ovaflopick.com, including any content, functionality, and services
          offered on or through www.ovaflopick.com (the &quot;
          <strong>Website</strong>&quot;), whether as a guest or a registered
          user.
        </p>
        <p>
          Please read the Terms of Use carefully before you start to use the
          Website.{' '}
          <strong>
            By using the Website or by clicking to accept or agree to the Terms
            of Use when this option is made available to you, you accept and
            agree to be bound and abide by these Terms of Use and our Privacy
            Policy, found at
            <NavLink
              className={classes.link}
              color="blue"
              preset="h6"
              text="Privacy Policy"
              tx="page.privacy"
              to="/privacy-policy"
            />
            , incorporated herein by reference.
          </strong>{' '}
          If you do not want to agree to these Terms of Use or the Privacy
          Policy, you must not access or use the Website.
        </p>
        <p>
          <strong>Changes to the Terms of Use</strong>
        </p>
        <p>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them, and apply to all access to and use of the Website thereafter.
          However, any changes to the dispute resolution provisions set out in{' '}
          <em>Governing Law And Jurisdiction</em> will not apply to any disputes
          for which the parties have actual notice before the date the change is
          posted on the Website.&nbsp;
        </p>
        <p>
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page frequently so you are aware of any
          changes, as they are binding on you.
        </p>
        <p>
          <strong>Accessing the Website and Account Security</strong>
        </p>
        <p>
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </p>
        <p>You are responsible for both:</p>
        <ul>
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>
          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Use and comply with
            them.
          </li>
        </ul>
        <p>
          To access the Website or some of the resources it offers, you may be
          asked to provide certain registration details or other information. It
          is a condition of your use of the Website that all the information you
          provide on the Website is correct, current, and complete. You agree
          that all information you provide to register with this Website or
          otherwise, including, but not limited to, through the use of any
          interactive features on the Website, is governed by our
          <NavLink
            className={classes.link}
            color="blue"
            preset="h6"
            text="Privacy Policy"
            tx="page.privacy"
            to="/privacy-policy"
          />
          , and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy.
        </p>
        <p>
          <br></br>
          <strong>Purchases &amp; Returns</strong>
        </p>
        <p>
          All purchases of items from Ovaflowpick.com transfer risk of loss and
          title to you upon delivery of the items to a carrier. Returns shall be
          approved or denied at the sole discretion of the&nbsp;<br></br>Company
          and are submittable to Us, by you, through our customer service
          support.
        </p>
        <p>
          <br></br>
          <strong>Subscription</strong>
        </p>
        <p>
          We offer a free subscription model to allow you access to our website,
          mobile applications, goods, and services.
        </p>
        <p>
          By purchasing a professional subscription, you agree to an initial and
          recurring monthly subscription fee at the then current monthly
          subscription rate. You accept responsibility for all recurring charges
          until you cancel your subscription. You may cancel your subscription
          at any time, subject to the terms of our cancellation policy.
        </p>
        <p>
          By purchasing a professional subscription, you acknowledge and grant
          Us permission to automatically process your monthly subscription fee
          according to our monthly billing cycle. We will continue to
          automatically process your subscription fee each month at the then
          current monthly subscription rate, until you cancel your
          subscription.&nbsp;
        </p>
        <p>
          Some fees, not associated with your subscription fee, may be
          nonrefundable fees.
        </p>
        <p>
          <strong>Buyer Accounts</strong>. If you choose, or are provided with,
          a user name, password, or any other piece of information as part of
          our security procedures, you must treat such information as
          confidential, and you must not disclose it to any other person or
          entity. You also acknowledge that your account is personal to you and
          agree not to provide any other person with access to this Website or
          portions of it using your user name, password, or other security
          information. You agree to notify us immediately of any unauthorized
          access to or use of your user name or password or any other breach of
          security. You also agree to ensure that you exit from your account at
          the end of each session. You should use particular caution when
          accessing your account from a public or shared computer so that others
          are not able to view or record your password or other personal
          information.
        </p>
        <p>
          We do not warrant the accuracy of product descriptions or other
          content on the Site.
        </p>
        <p>
          <strong>Seller Accounts.&nbsp;</strong>If you choose, or are provided
          with, a user name, password, or any other piece of information as part
          of our security procedures, you must treat such information as
          confidential, and you must not disclose it to any other person or
          entity. Your professional account is considered a commercial account
          and You agree to notify us immediately of any unauthorized access to
          or use of your user name or password or any other breach of security.
          You accept sole responsibility for providing accurate descriptions and
          depictions of products for sale on the Site.&nbsp;We reserves the
          right to refuse service, terminate or deactivate accounts, terminate
          your rights to use the Services as a Seller, remove or edit content,
          or cancel orders in our sole discretion.
        </p>
        <p>
          We do not warrant the accuracy of product descriptions or other
          content on the Site.
        </p>
        <p>
          We have the right to disable any user name, password, or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </p>
        <p>
          <br></br>
          <strong>Prohibited Uses</strong>
        </p>
        <p>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries).&nbsp;
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
          <li>
            To send, knowingly receive, upload, download, use, or re-use any
            material that does not comply with the Content Standards set out in
            these Terms of Use.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material without our prior written consent, including
            any &quot;junk mail,&quot; &quot;chain letter,&quot;
            &quot;spam,&quot; or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity (including,
            without limitation, by using email addresses associated with any of
            the foregoing).
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits
            anyone&apos;s use or enjoyment of the Website, or which, as
            determined by us, may harm the Company or users of the Website, or
            expose them to liability.
          </li>
        </ul>
        <p>Additionally, you agree not to:</p>
        <ul>
          <li>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party&apos;s
            use of the Website, including their ability to engage in real time
            activities through the Website.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms of Use, without our prior written consent.
          </li>
          <li>
            Use any device, software, or routine that interferes with the proper
            working of the Website.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material that is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer, or database connected to the
            Website.&nbsp;
          </li>
          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>
        <p>
          <br></br>
          <strong>User Contributions</strong>
        </p>
        <p>
          The Website may contain products for sale, product descriptions,
          images, and videos and other interactive features (collectively,
          &quot;<strong>Interactive Services</strong>&quot;) that allow Buyer
          and Seller users to post, submit, publish, display, or transmit to
          other users or other persons (hereinafter, &quot;<strong>post</strong>
          &quot;) content or materials (collectively, &quot;
          <strong>User Contributions</strong>&quot;) on or through the Website.
        </p>
        <p>
          All User Contributions must comply with the Content Standards set out
          in these Terms of Use.
        </p>
        <p>
          Any User Contribution you post to the site will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Website, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors, and assigns the right to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties any such
          material for any purpose.&nbsp;
        </p>
        <p>You represent and warrant that:&nbsp;</p>
        <ul>
          <li>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above to us and our
            affiliates and service providers, and each of their and our
            respective licensees, successors, and assigns.
          </li>
          <li>
            All of your User Contributions do and will comply with these Terms
            of Use.&nbsp;
          </li>
        </ul>
        <p>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness.
        </p>
        <p>
          Users may post reviews, comments, photos, videos, or other content so
          long as the content is not illegal, obscene, threatening, defamatory,
          invasive of privacy, infringing of intellectual property rights, or
          otherwise injurious to third parties or objectionable, within Our sole
          discretion. We reserve the sole right to remove any content from the
          Ovaflowpick Site.&nbsp;
        </p>
        <p>
          You grant to Us a nonexclusive, royalty-free, perpetual, and
          sublicensable right to reproduce, modify, adapt, publish, or use any
          and all user contributed content.
        </p>
        <p>
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user of
          the Website.
        </p>
        <p>
          <strong>Monitoring and Enforcement; Termination</strong>
        </p>
        <p>We have the right to:</p>
        <ul>
          <li>
            Remove or refuse to post any User Contributions for any or no reason
            in our sole discretion.
          </li>
          <li>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such User Contribution violates the Terms of Use,
            including the Content Standards, infringes any intellectual property
            right or other right of any person or entity, threatens the personal
            safety of users of the Website or the public, or could create
            liability for the Company.
          </li>
          <li>
            Disclose your identity or other information about you to any third
            party who claims that material posted by you violates their rights,
            including their intellectual property rights or their right to
            privacy.
          </li>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Website.&nbsp;
          </li>
          <li>
            Terminate or suspend your access to all or part of the Website for
            any or no reason, including without limitation, any violation of
            these Terms of Use.
          </li>
        </ul>
        <p>
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website. YOU WAIVE AND HOLD
          HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
          COMPANY DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
          THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p>
          However, we cannot review all material before it is posted on the
          Website, and cannot ensure prompt removal of objectionable material
          after it has been posted. Accordingly, we assume no liability for any
          action or inaction regarding transmissions, communications, or content
          provided by any user or third party. We have no liability or
          responsibility to anyone for performance or nonperformance of the
          activities described in this section.
        </p>
        <p>
          <strong>Content Standards</strong>
        </p>
        <p>
          These content standards apply to any and all User Contributions and
          use of Interactive Services. User Contributions must in their entirety
          comply with all applicable federal, state, local, and international
          laws and regulations. Without limiting the foregoing, User
          Contributions must not:
        </p>
        <ul>
          <li>
            Contain any material that is defamatory, obscene, indecent, abusive,
            offensive, harassing, violent, hateful, inflammatory, or otherwise
            objectionable.
          </li>
          <li>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation, or age.
          </li>
          <li>
            Infringe any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person.
          </li>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms of Use and our
            Privacy Policy
            <NavLink
              className={classes.link}
              color="blue"
              preset="h6"
              text="Privacy Policy"
              tx="page.privacy"
              to="/privacy-policy"
            />
            .
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </li>
          <li>
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, alarm, or annoy any other person.
          </li>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person or organization.&nbsp;
          </li>
          <li>
            Involve commercial activities or sales, such as contests,
            sweepstakes, and other sales promotions, barter, or advertising.
          </li>
          <li>
            Give the impression that they emanate from or are endorsed by us or
            any other person or entity, if this is not the case.
          </li>
        </ul>
        <p>
          <strong>Intellectual Property Rights</strong>
        </p>
        <p>
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <p>
          These Terms of Use permit you to use the Website for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the material on our
          Website, except as follows:
        </p>
        <ul>
          <li>
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
          <li>
            You may print or download one copy of a reasonable number of pages
            of the Website for your own personal, non-commercial use and not for
            further reproduction, publication, or distribution.
          </li>
          <li>
            If we provide desktop, mobile, or other applications for download,
            you may download a single copy to your computer or mobile device
            solely for your own personal, non-commercial use, provided you agree
            to be bound by our end user license agreement for such applications.
          </li>
        </ul>
        <p>You must not:</p>
        <ul>
          <li>Modify copies of any materials from this site.</li>
          <li>
            Use any illustrations, photographs, video or audio sequences, or any
            graphics separately from the accompanying text.
          </li>
          <li>
            Delete or alter any copyright, trademark, or other proprietary
            rights notices from copies of materials from this site.
          </li>
        </ul>
        <p>
          You must not access or use for any commercial purposes any part of the
          Website or any services or materials available through the
          Website.&nbsp;
        </p>
        <p>
          If you wish to make any use of material on the Website other than that
          set out in this section, please address your request to
          support@ovaflopick.com
        </p>
        <p>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Website in breach of the
          Terms of Use, your right to use the Website will stop immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. No right, title, or interest in or to the Website or
          any content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark, and other laws.
        </p>
        <p>
          <strong>Trademarks</strong>
        </p>
        <p>
          The Company name, the terms OVAFLO, INC, and all related names, logos,
          product and service names, designs, and slogans are trademarks of the
          Company or its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs, and slogans on this Website
          are the trademarks of their respective owners.
        </p>
        <p>
          <br></br>
          <strong>Copyright Infringement</strong>
        </p>
        <p>
          If you believe that any User Contributions violate your copyright,
          notice should be directed to: support@ovaflopick.com. It is the policy
          of the Company to terminate the user accounts of repeat infringers.
        </p>
        <p>
          <strong>Changes to the Website</strong>
        </p>
        <p>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>
        <p>
          <strong>Information About You and Your Visits to the Website</strong>
        </p>
        <p>
          All information we collect on this Website is subject to our Privacy
          Policy
          <NavLink
            className={classes.link}
            color="blue"
            preset="h6"
            text="Privacy Policy"
            tx="page.privacy"
            to="/privacy-policy"
          />
          . By using the Website, you consent to all actions taken by us with
          respect to your information in compliance with the Privacy Policy.
        </p>
        <p>
          <strong>Linking to the Website and Social Media Features</strong>
        </p>
        <p>
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but you must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part.&nbsp;
        </p>
        <p>
          This Website may provide certain social media features that enable you
          to:
        </p>
        <ul>
          <li>
            Link from your own or certain third-party websites to certain
            content on this Website.
          </li>
          <li>
            Send emails or other communications with certain content, or links
            to certain content, on this Website.
          </li>
          <li>
            Cause limited portions of content on this Website to be displayed or
            appear to be displayed on your own or certain third-party websites.
          </li>
        </ul>
        <p>
          You may use these features solely as they are provided by us and
          solely with respect to the content they are displayed with and
          otherwise in accordance with any additional terms and conditions we
          provide with respect to such features. Subject to the foregoing, you
          must not:
        </p>
        <ul>
          <li>Establish a link from any website that is not owned by you.</li>
          <li>
            Cause the Website or portions of it to be displayed on, or appear to
            be displayed by, any other site, for example, framing, deep linking,
            or in-line linking.
          </li>
          <li>Link to any part of the Website other than the homepage.</li>
          <li>
            Otherwise take any action with respect to the materials on this
            Website that is inconsistent with any other provision of these Terms
            of Use.
          </li>
        </ul>
        <p>
          The website from which you are linking, or on which you make certain
          content accessible, must comply in all respects with the Content
          Standards set out in these Terms of Use.
        </p>
        <p>
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to stop. We reserve the right to withdraw linking
          permission without notice.
        </p>
        <p>
          We may disable all or any social media features and any links at any
          time without notice in our discretion.
        </p>
        <p>
          <strong>Links from the Website</strong>
        </p>
        <p>
          If the Website contains links to other sites and resources provided by
          third parties, such as shipping services or delivery services, these
          links are provided for your convenience only. This includes links
          contained in advertisements, including banner advertisements and
          sponsored links. We have no control over the contents of those sites
          or resources, and accept no responsibility for them or for any loss or
          damage that may arise from your use of them. If you decide to access
          any of the third-party websites linked to this Website, you do so
          entirely at your own risk and subject to the terms and conditions of
          use for such websites.
        </p>
        <p>
          <strong>Geographic Restrictions</strong>
        </p>
        <p>
          The owner of the Website is based in the State of Texas in the United
          States. We provide this Website for use only by persons located in the
          United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If you access the Website from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </p>
        <p>
          <strong>Disclaimer of Warranties</strong>
        </p>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY WEBSITE LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
          THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
          WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE,
          ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE
          MEET YOUR NEEDS OR EXPECTATIONS.&nbsp;
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <strong>Limitation on Liability</strong>
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
          CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
          OR OTHERWISE, EVEN IF FORESEEABLE.&nbsp;
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <strong>Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys&apos; fees) arising
          out of or relating to your violation of these Terms of Use or your use
          of the Website, including, but not limited to, your User
          Contributions, any use of the Website&apos;s content, services, and
          products other than as expressly authorized in these Terms of Use, or
          your use of any information obtained from the Website.
        </p>
        <p>
          <strong>Governing Law and Jurisdiction</strong>
        </p>
        <p>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Texas without giving effect to any choice or conflict of law provision
          or rule (whether of the State of Texas or any other jurisdiction).
        </p>
        <p>
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          Texas, although we retain the right to bring any suit, action, or
          proceeding against you for breach of these Terms of Use in your
          country of residence or any other relevant country. You waive any and
          all objections to the exercise of jurisdiction over you by such courts
          and to venue in such courts.
        </p>
        <p>
          <strong>Limitation on Time to File Claims</strong>
        </p>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <p>
          <strong>Waiver and Severability</strong>
        </p>
        <p>
          No waiver by the Company of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>
        <p>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <p>
          <strong>Disputes</strong>
        </p>
        <p>
          Any dispute arising from this Agreement shall be resolved through
          binding arbitration conducted in accordance with the rules of the
          American Arbitration Association.
        </p>
        <p>
          <strong>Entire Agreement</strong>
        </p>
        <p>
          The Terms of Use and our Privacy Policy and End-User Licensing
          Agreement constitute the sole and entire agreement between you and
          Ovaflo, Inc. regarding the Website and supersede all prior and
          contemporaneous understandings, agreements, representations, and
          warranties, both written and oral, regarding the Website.
        </p>
        <p>
          <strong>Your Comments and Concerns</strong>
        </p>
        <p>
          This website is operated by Ovaflo, Inc located at 24044 Cinco Village
          Center Blvd, Suite #100 Katy, TX 77494 USA.
        </p>
        <p>
          All other feedback, comments, requests for technical support, and
          other communications relating to the Website should be directed to
          support@ovaflopick.com
        </p>
      </Column>
    </Content>
  )
}
