import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, Column } from '../../components'
import { useStyle } from './about-us.styles'

export const AboutUsPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const paddingContainer = '24px 24px 80px'

  return (
    <Content
      className={classes.container}
      padding={paddingContainer}
      justifyContent="flex-start"
    >
      <Text text="About Us" preset={titlePreset} />
      <Column className={classes.content} alignItems="flex-start">
        <Text
          preset="body"
          text="Ovaflopick is a marketplace where customers can get customized individualized products made for them by artisans and craftsmen. We believe made-for-me customized products should be accessible and affordable to people who want them. Therefore, on Ovaflopick, people communicate directly with the makers who make items based on individualized specific requests, and in turn, these products are created uniquely for them."
          tx="about.us.text"
        />
      </Column>
    </Content>
  )
}
