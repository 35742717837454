import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: 'calc(100% - 48px)'
  },
  name: {
    marginTop: 24
  },
  termsText: {
    maxWidth: 1124,
    marginTop: 24
  },
  dataName: {
    marginTop: 16
  },
  textName: {
    marginTop: 8
  },
  link: {
    display: 'inline-flex !important',
    marginLeft: 4
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: '24px 12px 40px 12px',
      width: 'calc(100% - 24px)'
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '40px 16px'
    }
  }
})
