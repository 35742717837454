import accessibility from './accessibility.svg'
import arrowLeft from './arrow_left.svg'
import arrowRight from './arrow_right.svg'
import arrowTop from './keyboard_arrow_top.svg'
import arrowBottom from './keyboard_arrow_bottom.svg'
import attention from './attention.svg'
import arrowBreadcrumbs from './arrow_breadcrumbs.svg'
import appStore from './app_store.svg'
import chat from './chat.svg'
import close from './close.svg'
import checkBox from './check_box.svg'
import facebook from './facebook.svg'
import favorite from './favorite.svg'
import favoriteBorder from './favorite_border.svg'
import female from './female.svg'
import googlePlay from './google_play.svg'
import hamburgerHover from './hamburger-hover.svg'
import hamburger from './hamburger.svg'
import history from './history.svg'
import instagram from './instagram.svg'
import leftArrow from './left_arrow.svg'
import linkedin from './linkedin.svg'
import localShipping from './local_shipping.svg'
import male from './male.svg'
import permIdentity from './perm_identity.svg'
import person from './person.svg'
import menu from './menu.svg'
import removeRedEye from './remove-red-eye.svg'
import rightArrow from './right_arrow.svg'
import refresh from './refresh.svg'
import search from './search.svg'
import settings from './settings.svg'
import twitter from './twitter.svg'
import uk from './uk.svg'
import visibilityOff from './visibility-off.svg'
import workOutline from './work_outline.svg'
import zoomIn from './zoom-in.svg'
import zoomOut from './zoom-out.svg'
import filledArrow from './filled-arrow.svg'
import location from './location.svg'
import localPost from './local_post.svg'
import localPhone from './local_phone.svg'
import favoriteFilled from './favorite-filled.svg'
import deleteIcon from './delete.svg'
import starBorder from './star-border.svg'
import starFilled from './star-filled.svg'
import doneAll from './done_all.svg'
import feedback from './feedback.svg'
import filterList from './filter_list.svg'
import sortArrows from './sort_arrows.svg'
import logoutMobile from './exit-to-app.svg'
import storeMallDirectory from './store_mall_directory.svg'
import percentage from './percentage.svg'
import mobileMinus from './mobile-minus.svg'
import mobilePlus from './mobile-plus.svg'
import maximize from './maximize.svg'
import paymentSuccess from './payment-success.svg'
import paymentError from './payment-error.svg'
import checkboxRound from './checkbox-round.svg'
import list from './list.svg'
import info from './info.svg'
import hintArrow from './hint_arrow.svg'
import twitterX from './twitter_x.svg'

export const ICONS = {
  accessibility,
  arrowBottom,
  arrowLeft,
  arrowRight,
  arrowTop,
  arrowBreadcrumbs,
  attention,
  appStore,
  chat,
  close,
  checkBox,
  doneAll,
  facebook,
  favorite,
  favoriteBorder,
  female,
  filterList,
  googlePlay,
  hamburgerHover,
  hamburger,
  history,
  instagram,
  leftArrow,
  linkedin,
  localShipping,
  male,
  menu,
  permIdentity,
  person,
  removeRedEye,
  rightArrow,
  refresh,
  search,
  settings,
  twitter,
  uk,
  visibilityOff,
  workOutline,
  zoomIn,
  zoomOut,
  filledArrow,
  location,
  localPost,
  localPhone,
  favoriteFilled,
  deleteIcon,
  starBorder,
  starFilled,
  feedback,
  sortArrows,
  logoutMobile,
  storeMallDirectory,
  percentage,
  mobileMinus,
  mobilePlus,
  maximize,
  paymentSuccess,
  paymentError,
  checkboxRound,
  list,
  info,
  hintArrow,
  twitterX
}
