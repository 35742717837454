import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, Column } from '../../components'
import { useStyle } from './privacy-policy.styles'

export const PrivacyPolicyPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const textPreset = isMobileOnly ? 'h7' : 'h5'
  const paddingContainer = '24px 24px 80px'

  return (
    <Content
      className={classes.container}
      padding={paddingContainer}
      justifyContent="flex-start"
    >
      <Text text="Privacy Policy" preset={titlePreset} tx="page.privacy" />

      <Text
        className={classes.name}
        color="inactive"
        preset={textPreset}
        text="Last modified: May 3, 2023"
        tx="privacy.create.date"
      />
      <Column className={classes.privacyCont} alignItems="flex-start">
        <p>
          <strong>Introduction</strong>
        </p>
        <p>
          Ovaflo, Inc. DBA Ovaflopick (<strong>&quot;Company&quot;</strong> or
          <strong>&nbsp;&quot;We&quot;</strong>) respects your privacy and are
          committed to protecting it through our compliance with this policy.
        </p>
        <p>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website www.ovaflopick.com
          (our &quot;<strong>Website</strong>&quot;) and our practices for
          collecting, using, maintaining, protecting, and disclosing that
          information.
        </p>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, and other electronic messages between you and this
            Website.
          </li>
          <li>
            Through mobile and desktop applications you download from this
            Website, which provide dedicated non-browser-based interaction
            between you and this Website.
          </li>
          <li>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this policy.
          </li>
        </ul>
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>
            Us offline or through any other means, including on any other
            website operated by Company or any third party (including our
            affiliates and subsidiaries); or&nbsp;
          </li>
          <li>
            Any third party (including our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from or on the Website.
          </li>
        </ul>
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time. Your
          continued use of this Website after we make changes is deemed to be
          acceptance of those changes, so please check the policy periodically
          for updates.&nbsp;
        </p>
        <p>
          <strong>Children Under the Age of 16</strong>
        </p>
        <p>
          Our Website is not intended for children under 16 years of age. No one
          under the age of 16 may provide any information to the Website. We do
          not knowingly collect personal information from children under 16. If
          you are under 16, do not use or provide any information on this
          Website or through any of its features, register on the Website, make
          any purchases through the Website, use any of the interactive or
          public comment features of this Website, or provide any information
          about yourself to us, including your name, address, telephone number,
          email address, or any screen name or user name you may use. If we
          learn we have collected or received personal information from a child
          under 16 without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 16, please contact us at support@ovafloinc.com
        </p>
        <p>
          <strong>
            Information We Collect About You and How We Collect It
          </strong>
        </p>
        <p>
          We collect several types of information from and about users of our
          Website, including:
        </p>
        <ul>
          <li>
            Information that you provide by filling in forms on our Website.
            This includes information provided at the time of registering to use
            our Website, subscribing to our service, posting material, or
            requesting further services. We may also ask you for information
            when you report a problem with our Website.
          </li>
          <li>
            Records and copies of your correspondence (including email
            addresses) if you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li>Your search queries on the Website.</li>
          <li>
            To understand you and your preferences to enhance your Website
            experience;
          </li>
          <li>
            To send our Customers and Partners information, including
            confirmations, account verification, invoices, technical notices,
            updates, security alerts, and support and administrative messages;
          </li>
          <li>
            To communicate with you about promotions, upcoming vents, and news
            about products and services offered by selected Partners;
          </li>
          <li>
            To link or combine information about you with other Personal
            Information we get from third parties, to help understand your needs
            and provide you with better and more personalized Website
            experience;
          </li>
          <li>
            To enforce our terms and conditions or protect our business,
            Partners, or users;
          </li>
          <li>
            To operate, maintain, and provide to you the features and
            functionality of the Website;
          </li>
          <li>
            To register you for Events you sign up for with us and populate
            profiles for you in our records;
          </li>
          <li>
            To communicate with you about Events or contact you for marketing
            purposes, in accordance with your marketing preferences;
          </li>
          <li>
            For industry analysis, benchmarking, analytics, marketing, and other
            business purposes;
          </li>
          <li>To operate and improve our Solutions and Services; or</li>
          <li>To provide our Services.</li>
        </ul>
        <p>We collect this information:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses, and
            information collected through cookies, web beacons, and other
            tracking technologies.
          </li>
        </ul>
        <p>
          You also may provide information to be published or displayed
          (hereinafter, &quot;<strong>posted</strong>&quot;) on public areas of
          the Website, or transmitted to other users of the Website or third
          parties (collectively, &quot;<strong>User Contributions</strong>
          &quot;). Your User Contributions are posted on and transmitted to
          others at your own risk. Although we limit access to certain pages,
          please be aware that no security measures are perfect or impenetrable.
          Additionally, we cannot control the actions of other users of the
          Website with whom you may choose to share your User Contributions.
          Therefore, we cannot and do not guarantee that your User Contributions
          will not be viewed by unauthorized persons.
        </p>
        <p>
          <em>
            Information We Collect Through Automatic Data Collection
            Technologies
          </em>
          <strong>
            <em>&nbsp;&nbsp;</em>
          </strong>
        </p>
        <p>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
        </p>
        <ul>
          <li>
            Details of your visits to our Website, including traffic data,
            location data, logs, and other communication data and the resources
            that you access and use on the Website.
          </li>
          <li>
            Information about your computer and internet connection, including
            your IP address, operating system, and browser type.
          </li>
        </ul>
        <p>
          We also may use these technologies to collect information about your
          online activities over time and across third-party websites or other
          online services (behavioral tracking).&nbsp;
        </p>
        <p>
          The information we collect automatically may include personal
          information, but we may maintain it or associate it with personal
          information we collect in other ways or receive from third parties. It
          helps us to improve our Website and to deliver a better and more
          personalized service, including by enabling us to:
        </p>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <p>
          The technologies we use for this automatic data collection may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies (or browser cookies).</strong> A cookie is a small
            file placed on the hard drive of your computer. You may refuse to
            accept browser cookies by activating the appropriate setting on your
            browser. However, if you select this setting you may be unable to
            access certain parts of our Website. Unless you have adjusted your
            browser setting so that it will refuse cookies, our system will
            issue cookies when you direct your browser to our Website.
            <ul>
              <li>
                You can set your browser not to accept cookies, and
                allaboutcookies.org&nbsp;tells you how to remove cookies from
                your browser. However, in a few cases, some of our website
                features may not function as a result.
              </li>
            </ul>
          </li>
          <li>
            <strong>Flash Cookies.</strong> Certain features of our Website may
            use local stored objects (or Flash cookies) to collect and store
            information about your preferences and navigation to, from, and on
            our Website. Flash cookies are not managed by the same browser
            settings as are used for browser cookies.
          </li>
          <li>
            <strong>
              For more information on cookies, visit allaboutcookies.
            </strong>
            org
          </li>
          <li>
            <strong>Web Beacons.</strong> Pages of our the Website and our
            e-mails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of certain website
            content and verifying system and server integrity).&nbsp;
          </li>
        </ul>
        <p>
          <strong>
            Third-Party Use of Cookies and Other Tracking Technologies
          </strong>
        </p>
        <p>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or
          other tracking technologies to collect information about you when you
          use our website. The information they collect may be associated with
          your personal information or they may collect information, including
          personal information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content.&nbsp;
        </p>
        <p>
          We do not control these third parties&apos; tracking technologies or
          how they may be used. If you have any questions about an advertisement
          or other targeted content, you should contact the responsible provider
          directly.
        </p>
        <p>
          <strong>How We Use Your Information</strong>
        </p>
        <p>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </p>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>
            To provide you with notices about your account, including expiration
            and renewal notices.
          </li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          We may also use your information to contact you about our own and
          third-parties&apos; goods and services that may be of interest to you.
          If you do not want us to use your information in this way, please
          adjust your user preferences in your account profile. For more
          information, see Choices About How We Use and Disclose Your
          Information.
        </p>
        <p>
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers&apos; target audiences. Even
          though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
        </p>
        <p>
          <strong>Disclosure of Your Information</strong>
        </p>
        <p>
          We may disclose aggregated information about our users without
          restriction.&nbsp;
        </p>
        <p>
          We may disclose personal information that we collect or you provide as
          described in this privacy policy:
        </p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business and who are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Ovaflopick&apos;s assets, whether as a
            going concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by Ovaflopick about
            our Website users is among the assets transferred.
          </li>
          <li>
            To fulfill the purpose for which you provide it. For example, if you
            give us an email address to use the &quot;email a friend&quot;
            feature of our Website, we will transmit the contents of that email
            and your email address to the recipients.
          </li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
        </ul>
        <p>We may also disclose your personal information:</p>
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our terms of use
            https://ovafloinc.com/ovaflo-terms-of-service.html and other
            agreements, including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Ovaflopick, our customers, or others.
            This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
        </ul>
        <p>
          <strong>Accessing and Correcting Your Information</strong>
        </p>
        <p>You have the following rights with respect to your information:</p>
        <p>To review your information that you have supplied to us;</p>
        <p>
          To request that we correct any errors, outdated information, or
          omissions your information that you have supplied to us;
        </p>
        <p>To request that your information not be used to contact you;</p>
        <p>
          To request that your information be removed from any solicitation list
          that we use;
        </p>
        <p>To request that your information be deleted from our records;</p>
        <p>
          To exercise any of these rights, please contact us at
          support@ovafloinc.com or by mail at 24044 Cinco Village Center Blvd,
          Suite #100 Katy, TX 77494 USA Attention: Privacy. We will promptly
          change, correct, or delete your information and notify you of the
          action we have taken.
        </p>
        <p>
          <strong>Your California Privacy Rights</strong>
        </p>
        <p>
          California Online Privacy Protection Act. As required by the
          California Online Privacy Protection Act (&ldquo;California
          Act&rdquo;) and the California Business and Professions Code, this
          Privacy Statement identifies the categories of personally identifiable
          information (as that term is defined above and in the California Act)
          that we collect through our Site about individual consumers who use or
          visit our Site and the categories of third-party persons or entities
          with whom such personally identifiable information may be shared. See
          more about the California Act at
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf&nbsp;
        </p>
        <p>
          California&apos;s &quot;Shine the Light&quot; law (Civil Code Section
          &sect; 1798.83) permits users of our App that are California residents
          to request certain information regarding our disclosure of personal
          information to third parties for their direct marketing purposes. To
          make such a request, please send an email to support@ovafloinc.com or
          write us at 24044 Cinco Village Center Blvd, Suite #100 Katy, TX 77494
          USA.
        </p>
        <p>
          <strong>Data Security</strong>
        </p>
        <p>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. All information you provide to us is
          stored on our secure servers behind firewalls. Any payment
          transactions and personal data will be encrypted using SSL
          technology.&nbsp;
        </p>
        <p>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone. We urge you to be careful about giving out information in
          public areas of the Website like message boards. The information you
          share in public areas may be viewed by any user of the Website.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </p>
        <p>
          <strong>Marketing</strong>
        </p>
        <p>
          Our Company would like to send you information about products and
          services of ours that we think you might like, as well as those of our
          partner companies.
        </p>
        <p>
          If you have agreed to receive marketing, you may always opt out at a
          later date.
        </p>
        <p>
          You have the right at any time to stop Our Company from contacting you
          for marketing purposes or giving your data to other members of the Our
          Company Group.
        </p>
        <p>
          If you no longer wish to be contacted for marketing purposes, please
          click here.
        </p>
        <p>
          <strong>What are your data protection rights?</strong>
        </p>
        <p>
          Our Company would like to make sure you are fully aware of all of your
          data protection rights. Every user is entitled to the following:
        </p>
        <p>
          <strong>The right to access</strong> &ndash; You have the right to
          request Our Company for copies of your personal data. We may charge
          you a small fee for this service.
        </p>
        <p>
          <strong>The right to rectification</strong> &ndash; You have the right
          to request that Our Company correct any information you believe is
          inaccurate. You also have the right to request Our Company to complete
          the information you believe is incomplete.
        </p>
        <p>
          <strong>The right to erasure</strong> &ndash; You have the right to
          request that Our Company erase your personal data, under certain
          conditions.
        </p>
        <p>
          <strong>The right to restrict processing</strong> &ndash; You have the
          right to request that Our Company restrict the processing of your
          personal data, under certain conditions.
        </p>
        <p>
          <strong>The right to object to processing</strong> &ndash; You have
          the right to object to Our Company&rsquo;s processing of your personal
          data, under certain conditions.
        </p>
        <p>
          <strong>The right to data portability</strong> &ndash; You have the
          right to request that Our Company transfer the data that we have
          collected to another organization, or directly to you, under certain
          conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at our
          email: support@ovafloinc.com or call us at 281-393-7084.&nbsp;
        </p>
        <p>
          <strong>Privacy policies of other websites</strong>
        </p>
        <p>
          The Our Company website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
        </p>
        <p>
          <strong>Changes to Our Privacy Policy</strong>
        </p>
        <p>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users&apos;
          personal information, we will notify you by email to the email address
          specified in your account. The date the privacy policy was last
          revised is identified at the top of the page. You are responsible for
          ensuring we have an up-to-date active and deliverable email address
          for you, and for periodically visiting our Website and this privacy
          policy to check for any changes.
        </p>
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at:&nbsp;
        </p>
        <p>support@ovafloinc.com or call us at 281-393-7084.&nbsp;</p>
        <p>Ovaflo, Inc. DBA Ovaflopick</p>
        <p>24044 Cinco Village Center Blvd, Suite #100 Katy, TX 77494 USA.</p>
        <p>
          To register a complaint or concern, please send an email to
          support@ovafloinc.com &nbsp;
        </p>
      </Column>
    </Content>
  )
}
