import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

import { ContentPreset, ContentStyles } from './content.types'

export const presetStyles = (preset?: ContentPreset) => {
  const style: Record<ContentPreset, any> = {
    default: {},
    part: {
      width: isMobile ? 'calc(100% - 24px)' : 1172,
      padding: isMobile ? '18px 12px 40px 12px' : '24px 0'
    }
  }

  if (!preset) {
    return style.default
  }

  return style[preset]
}

export const useStyle = createUseStyles({
  container: ({ preset, padding }: ContentStyles) => {
    return {
      width: 'calc(100% - 32px)',
      padding: padding || '0px 16px',
      maxWidth: 1720,
      flex: 1,
      ...presetStyles(preset)
    }
  },
  '@media screen and (max-width: 1720px)': {
    container: {
      maxWidth: 'calc(100% - 32px)',
      padding: '0px 16px'
    }
  },

  '@media screen and (max-width: 480px)': {
    container: ({ preset }: ContentStyles) => {
      return {
        width: 'calc(100% - 24px)',
        padding: '0px 12px',
        maxWidth: 1720,
        flex: 1,
        ...presetStyles(preset)
      }
    }
  }
})
